// generated with @7nohe/openapi-react-query-codegen@1.3.0 

import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { AuthService, BiService, DataService, DatabaseService, DefaultService, LangChainService, MessagesService, OrgService, TrackerService, UserService, WeaviateService } from "../requests/services.gen";
import { Body_auth_jwt_login_auth_jwt_login_post, Body_reset_forgot_password_auth_forgot_password_post, Body_reset_reset_password_auth_reset_password_post, Body_upload_file_datasources_file_upload_post, Body_verify_verify_auth_verify_post, DataPreviewRequest, DepartmentCreate, DepartmentUpdate, GeneralFiltersRequest, GrossProfitPerPeriodRequest, GrossProfitRequest, GrossProfitTrendRequest, KpisRequest, ManagedUserCreate, ManagedUserInvite, OrganizationCreate, Prompt, ReclameCostPerPeriodRequest, RegressionRequest, RevenuePerPeriodRequest, SalesForecastRequest, SeasonalSalesProfitRequest, UserCreate, UserMessageIn, UserUpdate } from "../requests/types.gen";
import * as Common from "./common";
/**
* Db Health
* Check if the postgres database is up and running.
* @returns unknown Successful Response
* @throws ApiError
*/
export const useDatabaseServiceDbHealth = <TData = Common.DatabaseServiceDbHealthDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseDatabaseServiceDbHealthKeyFn(), queryFn: () => DatabaseService.dbHealth() as TData, ...options });
/**
* Run Sql Query
* Run an SQL query on a table.
* @param data The data for the request.
* @param data.tableName
* @param data.query
* @returns unknown Successful Response
* @throws ApiError
*/
export const useDatabaseServiceRunSqlQuery = <TData = Common.DatabaseServiceRunSqlQueryDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ query, tableName }: {
  query: string;
  tableName: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseDatabaseServiceRunSqlQueryKeyFn({ query, tableName }, queryKey), queryFn: () => DatabaseService.runSqlQuery({ query, tableName }) as TData, ...options });
/**
* Get Table Info
* Get table information from SQL database.
* @returns unknown Successful Response
* @throws ApiError
*/
export const useLangChainServiceGetTableInfo = <TData = Common.LangChainServiceGetTableInfoDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseLangChainServiceGetTableInfoKeyFn(), queryFn: () => LangChainService.getTableInfo() as TData, ...options });
/**
* Health
* @returns unknown Successful Response
* @throws ApiError
*/
export const useDefaultServiceHealth = <TData = Common.DefaultServiceHealthDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseDefaultServiceHealthKeyFn(), queryFn: () => DefaultService.health() as TData, ...options });
/**
* Weaviate Health
* Check the health status of the Weaviate instance.
* @param data The data for the request.
* @param data.self
* @returns unknown Successful Response
* @throws ApiError
*/
export const useWeaviateServiceWeaviateHealth = <TData = Common.WeaviateServiceWeaviateHealthDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ self }: {
  self: unknown;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseWeaviateServiceWeaviateHealthKeyFn({ self }, queryKey), queryFn: () => WeaviateService.weaviateHealth({ self }) as TData, ...options });
/**
* Weaviate Info
* Get the info of the Weaviate instance.
* @param data The data for the request.
* @param data.self
* @returns unknown Successful Response
* @throws ApiError
*/
export const useWeaviateServiceWeaviateInfo = <TData = Common.WeaviateServiceWeaviateInfoDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ self }: {
  self: unknown;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseWeaviateServiceWeaviateInfoKeyFn({ self }, queryKey), queryFn: () => WeaviateService.weaviateInfo({ self }) as TData, ...options });
/**
* Weaviate Indexes
* Get a list of all active indexes in Weaviate.
* @param data The data for the request.
* @param data.self
* @returns unknown Successful Response
* @throws ApiError
*/
export const useWeaviateServiceWeaviateIndexes = <TData = Common.WeaviateServiceWeaviateIndexesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ self }: {
  self: unknown;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseWeaviateServiceWeaviateIndexesKeyFn({ self }, queryKey), queryFn: () => WeaviateService.weaviateIndexes({ self }) as TData, ...options });
/**
* Weaviate Index Exist
* Check if an index exists in Weaviate.
* @param data The data for the request.
* @param data.indexName
* @param data.self
* @returns unknown Successful Response
* @throws ApiError
*/
export const useWeaviateServiceWeaviateIndexExist = <TData = Common.WeaviateServiceWeaviateIndexExistDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ indexName, self }: {
  indexName: string;
  self: unknown;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseWeaviateServiceWeaviateIndexExistKeyFn({ indexName, self }, queryKey), queryFn: () => WeaviateService.weaviateIndexExist({ indexName, self }) as TData, ...options });
/**
* Weaviate Get Index Count
* Get the count of objects in an index in Weaviate.
* @param data The data for the request.
* @param data.indexName
* @param data.self
* @returns unknown Successful Response
* @throws ApiError
*/
export const useWeaviateServiceWeaviateGetIndexCount = <TData = Common.WeaviateServiceWeaviateGetIndexCountDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ indexName, self }: {
  indexName: string;
  self: unknown;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseWeaviateServiceWeaviateGetIndexCountKeyFn({ indexName, self }, queryKey), queryFn: () => WeaviateService.weaviateGetIndexCount({ indexName, self }) as TData, ...options });
/**
* Weaviate Search Index
* Search an index in Weaviate.
* @param data The data for the request.
* @param data.indexName
* @param data.self
* @param data.query
* @param data.topK
* @returns unknown Successful Response
* @throws ApiError
*/
export const useWeaviateServiceWeaviateSearchIndex = <TData = Common.WeaviateServiceWeaviateSearchIndexDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ indexName, query, self, topK }: {
  indexName: string;
  query?: string;
  self: unknown;
  topK?: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseWeaviateServiceWeaviateSearchIndexKeyFn({ indexName, query, self, topK }, queryKey), queryFn: () => WeaviateService.weaviateSearchIndex({ indexName, query, self, topK }) as TData, ...options });
/**
* Get Summary
* @param data The data for the request.
* @param data.tableName Name of the table
* @returns SummaryModel Successful Response
* @throws ApiError
*/
export const useBiServiceGetSummary = <TData = Common.BiServiceGetSummaryDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ tableName }: {
  tableName: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseBiServiceGetSummaryKeyFn({ tableName }, queryKey), queryFn: () => BiService.getSummary({ tableName }) as TData, ...options });
/**
* Get Filter Options
* @param data The data for the request.
* @param data.tableName Name of the table
* @returns GeneralFilters Successful Response
* @throws ApiError
*/
export const useBiServiceGetFilterOptions = <TData = Common.BiServiceGetFilterOptionsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ tableName }: {
  tableName: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseBiServiceGetFilterOptionsKeyFn({ tableName }, queryKey), queryFn: () => BiService.getFilterOptions({ tableName }) as TData, ...options });
/**
* Get Prompt Library
* @returns PromptOut Successful Response
* @throws ApiError
*/
export const useTrackerServiceGetPromptLibrary = <TData = Common.TrackerServiceGetPromptLibraryDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTrackerServiceGetPromptLibraryKeyFn(), queryFn: () => TrackerService.getPromptLibrary() as TData, ...options });
/**
* Get Conversations
* @returns ChatHistory Successful Response
* @throws ApiError
*/
export const useTrackerServiceGetConversations = <TData = Common.TrackerServiceGetConversationsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTrackerServiceGetConversationsKeyFn(), queryFn: () => TrackerService.getConversations() as TData, ...options });
/**
* Get Events
* @param data The data for the request.
* @param data.conversationId
* @returns Conversation Successful Response
* @throws ApiError
*/
export const useTrackerServiceGetEvents = <TData = Common.TrackerServiceGetEventsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ conversationId }: {
  conversationId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseTrackerServiceGetEventsKeyFn({ conversationId }, queryKey), queryFn: () => TrackerService.getEvents({ conversationId }) as TData, ...options });
/**
* Get Datasources
* @returns Datasource Successful Response
* @throws ApiError
*/
export const useDataServiceGetDatasources = <TData = Common.DataServiceGetDatasourcesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseDataServiceGetDatasourcesKeyFn(), queryFn: () => DataService.getDatasources() as TData, ...options });
/**
* Get Datasource By Id
* @param data The data for the request.
* @param data.datasourceId
* @returns Datasource Successful Response
* @throws ApiError
*/
export const useDataServiceGetDatasourceById = <TData = Common.DataServiceGetDatasourceByIdDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ datasourceId }: {
  datasourceId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseDataServiceGetDatasourceByIdKeyFn({ datasourceId }, queryKey), queryFn: () => DataService.getDatasourceById({ datasourceId }) as TData, ...options });
/**
* Users:Current User
* @returns UserRead Successful Response
* @throws ApiError
*/
export const useUserServiceUsersCurrentUser = <TData = Common.UserServiceUsersCurrentUserDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUserServiceUsersCurrentUserKeyFn(), queryFn: () => UserService.usersCurrentUser() as TData, ...options });
/**
* Users:User
* @param data The data for the request.
* @param data.id
* @returns UserRead Successful Response
* @throws ApiError
*/
export const useUserServiceUsersUser = <TData = Common.UserServiceUsersUserDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUserServiceUsersUserKeyFn({ id }, queryKey), queryFn: () => UserService.usersUser({ id }) as TData, ...options });
/**
* Get Organizations
* @returns OrganizationRead Successful Response
* @throws ApiError
*/
export const useOrgServiceGetOrganizations = <TData = Common.OrgServiceGetOrganizationsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseOrgServiceGetOrganizationsKeyFn(), queryFn: () => OrgService.getOrganizations() as TData, ...options });
/**
* Get Organization
* @param data The data for the request.
* @param data.orgId
* @returns OrganizationRead Successful Response
* @throws ApiError
*/
export const useOrgServiceGetOrganization = <TData = Common.OrgServiceGetOrganizationDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ orgId }: {
  orgId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseOrgServiceGetOrganizationKeyFn({ orgId }, queryKey), queryFn: () => OrgService.getOrganization({ orgId }) as TData, ...options });
/**
* Get Organization Users
* @param data The data for the request.
* @param data.orgId
* @returns UserRead Successful Response
* @throws ApiError
*/
export const useOrgServiceGetOrganizationUsers = <TData = Common.OrgServiceGetOrganizationUsersDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ orgId }: {
  orgId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseOrgServiceGetOrganizationUsersKeyFn({ orgId }, queryKey), queryFn: () => OrgService.getOrganizationUsers({ orgId }) as TData, ...options });
/**
* Get Organization Departments
* @param data The data for the request.
* @param data.orgId
* @returns DepartmentRead Successful Response
* @throws ApiError
*/
export const useOrgServiceGetOrganizationDepartments = <TData = Common.OrgServiceGetOrganizationDepartmentsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ orgId }: {
  orgId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseOrgServiceGetOrganizationDepartmentsKeyFn({ orgId }, queryKey), queryFn: () => OrgService.getOrganizationDepartments({ orgId }) as TData, ...options });
/**
* New Message
* @param data The data for the request.
* @param data.requestBody
* @returns EventOut Successful Response
* @throws ApiError
*/
export const useMessagesServiceNewMessage = <TData = Common.MessagesServiceNewMessageMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: UserMessageIn;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: UserMessageIn;
}, TContext>({ mutationFn: ({ requestBody }) => MessagesService.newMessage({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* New Message Streaming
* @param data The data for the request.
* @param data.requestBody
* @returns string Successful Response
* @throws ApiError
*/
export const useMessagesServiceNewMessageStreaming = <TData = Common.MessagesServiceNewMessageStreamingMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: UserMessageIn;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: UserMessageIn;
}, TContext>({ mutationFn: ({ requestBody }) => MessagesService.newMessageStreaming({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Weaviate Create Index
* Create a new index in Weaviate.
* @param data The data for the request.
* @param data.indexName
* @param data.self
* @returns unknown Successful Response
* @throws ApiError
*/
export const useWeaviateServiceWeaviateCreateIndex = <TData = Common.WeaviateServiceWeaviateCreateIndexMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  indexName: string;
  self: unknown;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  indexName: string;
  self: unknown;
}, TContext>({ mutationFn: ({ indexName, self }) => WeaviateService.weaviateCreateIndex({ indexName, self }) as unknown as Promise<TData>, ...options });
/**
* Weaviate Upsert Index
* Upsert data to an index in Weaviate.
* @param data The data for the request.
* @param data.indexName
* @param data.self
* @returns unknown Successful Response
* @throws ApiError
*/
export const useWeaviateServiceWeaviateUpsertIndex = <TData = Common.WeaviateServiceWeaviateUpsertIndexMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  indexName: string;
  self: unknown;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  indexName: string;
  self: unknown;
}, TContext>({ mutationFn: ({ indexName, self }) => WeaviateService.weaviateUpsertIndex({ indexName, self }) as unknown as Promise<TData>, ...options });
/**
* Get Kpi
* @param data The data for the request.
* @param data.tableName Name of the table
* @param data.requestBody
* @returns KPIModel Successful Response
* @throws ApiError
*/
export const useBiServiceGetKpi = <TData = Common.BiServiceGetKpiMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: KpisRequest;
  tableName: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: KpisRequest;
  tableName: string;
}, TContext>({ mutationFn: ({ requestBody, tableName }) => BiService.getKpi({ requestBody, tableName }) as unknown as Promise<TData>, ...options });
/**
* Get Gross Profit By Period
* @param data The data for the request.
* @param data.tableName Name of the table
* @param data.requestBody
* @returns GrossProfitByPeriodModel Successful Response
* @throws ApiError
*/
export const useBiServiceGetGrossProfitByPeriod = <TData = Common.BiServiceGetGrossProfitByPeriodMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: GrossProfitPerPeriodRequest;
  tableName: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: GrossProfitPerPeriodRequest;
  tableName: string;
}, TContext>({ mutationFn: ({ requestBody, tableName }) => BiService.getGrossProfitByPeriod({ requestBody, tableName }) as unknown as Promise<TData>, ...options });
/**
* Get Gross Profit Per Region
* @param data The data for the request.
* @param data.tableName Name of the table
* @param data.requestBody
* @returns GrossProfitPerRegionModel Successful Response
* @throws ApiError
*/
export const useBiServiceGetGrossProfitPerRegion = <TData = Common.BiServiceGetGrossProfitPerRegionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: GrossProfitRequest;
  tableName: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: GrossProfitRequest;
  tableName: string;
}, TContext>({ mutationFn: ({ requestBody, tableName }) => BiService.getGrossProfitPerRegion({ requestBody, tableName }) as unknown as Promise<TData>, ...options });
/**
* Get Gross Profit Trend
* @param data The data for the request.
* @param data.tableName Name of the table
* @param data.requestBody
* @returns GrossProfitTrendModel Successful Response
* @throws ApiError
*/
export const useBiServiceGetGrossProfitTrend = <TData = Common.BiServiceGetGrossProfitTrendMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: GrossProfitTrendRequest;
  tableName: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: GrossProfitTrendRequest;
  tableName: string;
}, TContext>({ mutationFn: ({ requestBody, tableName }) => BiService.getGrossProfitTrend({ requestBody, tableName }) as unknown as Promise<TData>, ...options });
/**
* Get Seasonal Sales Profit
* @param data The data for the request.
* @param data.tableName Name of the table
* @param data.requestBody
* @returns SeasonalSalesProfitModel Successful Response
* @throws ApiError
*/
export const useBiServiceGetSeasonalSalesProfit = <TData = Common.BiServiceGetSeasonalSalesProfitMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: SeasonalSalesProfitRequest;
  tableName: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: SeasonalSalesProfitRequest;
  tableName: string;
}, TContext>({ mutationFn: ({ requestBody, tableName }) => BiService.getSeasonalSalesProfit({ requestBody, tableName }) as unknown as Promise<TData>, ...options });
/**
* Get Revenue Per Period
* @param data The data for the request.
* @param data.tableName Name of the table
* @param data.requestBody
* @returns RevenuePerPeriodModel Successful Response
* @throws ApiError
*/
export const useBiServiceGetRevenuePerPeriod = <TData = Common.BiServiceGetRevenuePerPeriodMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: RevenuePerPeriodRequest;
  tableName: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: RevenuePerPeriodRequest;
  tableName: string;
}, TContext>({ mutationFn: ({ requestBody, tableName }) => BiService.getRevenuePerPeriod({ requestBody, tableName }) as unknown as Promise<TData>, ...options });
/**
* Get Reclame Cost Per Period
* @param data The data for the request.
* @param data.tableName Name of the table
* @param data.requestBody
* @returns ReclameCostPerPeriodModel Successful Response
* @throws ApiError
*/
export const useBiServiceGetReclameCostPerPeriod = <TData = Common.BiServiceGetReclameCostPerPeriodMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ReclameCostPerPeriodRequest;
  tableName: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ReclameCostPerPeriodRequest;
  tableName: string;
}, TContext>({ mutationFn: ({ requestBody, tableName }) => BiService.getReclameCostPerPeriod({ requestBody, tableName }) as unknown as Promise<TData>, ...options });
/**
* Get Sales Forecast
* @param data The data for the request.
* @param data.tableName Name of the table
* @param data.requestBody
* @returns SalesForecastResult Successful Response
* @throws ApiError
*/
export const useBiServiceGetSalesForecast = <TData = Common.BiServiceGetSalesForecastMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: SalesForecastRequest;
  tableName: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: SalesForecastRequest;
  tableName: string;
}, TContext>({ mutationFn: ({ requestBody, tableName }) => BiService.getSalesForecast({ requestBody, tableName }) as unknown as Promise<TData>, ...options });
/**
* Get Shell Kpis
* @param data The data for the request.
* @param data.tableName Name of the table
* @param data.requestBody
* @returns KPIModel Successful Response
* @throws ApiError
*/
export const useBiServiceGetShellKpis = <TData = Common.BiServiceGetShellKpisMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: GeneralFiltersRequest;
  tableName: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: GeneralFiltersRequest;
  tableName: string;
}, TContext>({ mutationFn: ({ requestBody, tableName }) => BiService.getShellKpis({ requestBody, tableName }) as unknown as Promise<TData>, ...options });
/**
* Run Regression
* @param data The data for the request.
* @param data.tableName Name of the table
* @param data.requestBody
* @returns RegressionResult Successful Response
* @throws ApiError
*/
export const useBiServiceRunRegression = <TData = Common.BiServiceRunRegressionMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: RegressionRequest;
  tableName: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: RegressionRequest;
  tableName: string;
}, TContext>({ mutationFn: ({ requestBody, tableName }) => BiService.runRegression({ requestBody, tableName }) as unknown as Promise<TData>, ...options });
/**
* Create Prompt
* @param data The data for the request.
* @param data.requestBody
* @returns PromptOut Successful Response
* @throws ApiError
*/
export const useTrackerServiceCreatePrompt = <TData = Common.TrackerServiceCreatePromptMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: Prompt;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: Prompt;
}, TContext>({ mutationFn: ({ requestBody }) => TrackerService.createPrompt({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Get Preview
* @param data The data for the request.
* @param data.datasourceName
* @param data.requestBody
* @returns DataPreview Successful Response
* @throws ApiError
*/
export const useDataServiceGetPreview = <TData = Common.DataServiceGetPreviewMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  datasourceName: string;
  requestBody: DataPreviewRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  datasourceName: string;
  requestBody: DataPreviewRequest;
}, TContext>({ mutationFn: ({ datasourceName, requestBody }) => DataService.getPreview({ datasourceName, requestBody }) as unknown as Promise<TData>, ...options });
/**
* Upload File
* Uploads a CSV or Excel file to the database while streaming large data.
* @param data The data for the request.
* @param data.tableName
* @param data.formData
* @returns Datasource Successful Response
* @throws ApiError
*/
export const useDataServiceUploadFile = <TData = Common.DataServiceUploadFileMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  formData: Body_upload_file_datasources_file_upload_post;
  tableName: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  formData: Body_upload_file_datasources_file_upload_post;
  tableName: string;
}, TContext>({ mutationFn: ({ formData, tableName }) => DataService.uploadFile({ formData, tableName }) as unknown as Promise<TData>, ...options });
/**
* Auth:Jwt.Login
* @param data The data for the request.
* @param data.formData
* @returns unknown Successful Response
* @returns void No Content
* @throws ApiError
*/
export const useAuthServiceAuthJwtLogin = <TData = Common.AuthServiceAuthJwtLoginMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  formData: Body_auth_jwt_login_auth_jwt_login_post;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  formData: Body_auth_jwt_login_auth_jwt_login_post;
}, TContext>({ mutationFn: ({ formData }) => AuthService.authJwtLogin({ formData }) as unknown as Promise<TData>, ...options });
/**
* Auth:Jwt.Logout
* @returns unknown Successful Response
* @returns void No Content
* @throws ApiError
*/
export const useAuthServiceAuthJwtLogout = <TData = Common.AuthServiceAuthJwtLogoutMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, void, TContext>, "mutationFn">) => useMutation<TData, TError, void, TContext>({ mutationFn: () => AuthService.authJwtLogout() as unknown as Promise<TData>, ...options });
/**
* Register:Register
* @param data The data for the request.
* @param data.requestBody
* @returns UserRead Successful Response
* @throws ApiError
*/
export const useAuthServiceRegisterRegister = <TData = Common.AuthServiceRegisterRegisterMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: UserCreate;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: UserCreate;
}, TContext>({ mutationFn: ({ requestBody }) => AuthService.registerRegister({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Reset:Forgot Password
* @param data The data for the request.
* @param data.requestBody
* @returns unknown Successful Response
* @throws ApiError
*/
export const useAuthServiceResetForgotPassword = <TData = Common.AuthServiceResetForgotPasswordMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: Body_reset_forgot_password_auth_forgot_password_post;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: Body_reset_forgot_password_auth_forgot_password_post;
}, TContext>({ mutationFn: ({ requestBody }) => AuthService.resetForgotPassword({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Reset:Reset Password
* @param data The data for the request.
* @param data.requestBody
* @returns unknown Successful Response
* @throws ApiError
*/
export const useAuthServiceResetResetPassword = <TData = Common.AuthServiceResetResetPasswordMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: Body_reset_reset_password_auth_reset_password_post;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: Body_reset_reset_password_auth_reset_password_post;
}, TContext>({ mutationFn: ({ requestBody }) => AuthService.resetResetPassword({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Verify:Request-Token
* @returns unknown Successful Response
* @throws ApiError
*/
export const useAuthServiceVerifyRequestToken = <TData = Common.AuthServiceVerifyRequestTokenMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, void, TContext>, "mutationFn">) => useMutation<TData, TError, void, TContext>({ mutationFn: () => AuthService.verifyRequestToken() as unknown as Promise<TData>, ...options });
/**
* Verify:Verify
* @param data The data for the request.
* @param data.requestBody
* @returns UserRead Successful Response
* @throws ApiError
*/
export const useAuthServiceVerifyVerify = <TData = Common.AuthServiceVerifyVerifyMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: Body_verify_verify_auth_verify_post;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: Body_verify_verify_auth_verify_post;
}, TContext>({ mutationFn: ({ requestBody }) => AuthService.verifyVerify({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Create Organization
* @param data The data for the request.
* @param data.requestBody
* @returns unknown Successful Response
* @throws ApiError
*/
export const useOrgServiceCreateOrganization = <TData = Common.OrgServiceCreateOrganizationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: OrganizationCreate;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: OrganizationCreate;
}, TContext>({ mutationFn: ({ requestBody }) => OrgService.createOrganization({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Create User For Organization
* @param data The data for the request.
* @param data.orgId
* @param data.requestBody
* @returns UserRead Successful Response
* @throws ApiError
*/
export const useOrgServiceCreateUserForOrganization = <TData = Common.OrgServiceCreateUserForOrganizationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  orgId: string;
  requestBody: ManagedUserCreate;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  orgId: string;
  requestBody: ManagedUserCreate;
}, TContext>({ mutationFn: ({ orgId, requestBody }) => OrgService.createUserForOrganization({ orgId, requestBody }) as unknown as Promise<TData>, ...options });
/**
* Create Department
* @param data The data for the request.
* @param data.orgId
* @param data.requestBody
* @returns DepartmentRead Successful Response
* @throws ApiError
*/
export const useOrgServiceCreateDepartment = <TData = Common.OrgServiceCreateDepartmentMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  orgId: string;
  requestBody: DepartmentCreate;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  orgId: string;
  requestBody: DepartmentCreate;
}, TContext>({ mutationFn: ({ orgId, requestBody }) => OrgService.createDepartment({ orgId, requestBody }) as unknown as Promise<TData>, ...options });
/**
* Accept Invite User To Organization
* @param data The data for the request.
* @param data.orgId
* @returns unknown Successful Response
* @throws ApiError
*/
export const useOrgServiceAcceptInviteUserToOrganization = <TData = Common.OrgServiceAcceptInviteUserToOrganizationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  orgId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  orgId: string;
}, TContext>({ mutationFn: ({ orgId }) => OrgService.acceptInviteUserToOrganization({ orgId }) as unknown as Promise<TData>, ...options });
/**
* Decline Invite User To Organization
* @param data The data for the request.
* @param data.orgId
* @returns unknown Successful Response
* @throws ApiError
*/
export const useOrgServiceDeclineInviteUserToOrganization = <TData = Common.OrgServiceDeclineInviteUserToOrganizationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  orgId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  orgId: string;
}, TContext>({ mutationFn: ({ orgId }) => OrgService.declineInviteUserToOrganization({ orgId }) as unknown as Promise<TData>, ...options });
/**
* Update Department
* @param data The data for the request.
* @param data.orgId
* @param data.departmentId
* @param data.requestBody
* @returns DepartmentRead Successful Response
* @throws ApiError
*/
export const useOrgServiceUpdateDepartment = <TData = Common.OrgServiceUpdateDepartmentMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  departmentId: string;
  orgId: string;
  requestBody: DepartmentUpdate;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  departmentId: string;
  orgId: string;
  requestBody: DepartmentUpdate;
}, TContext>({ mutationFn: ({ departmentId, orgId, requestBody }) => OrgService.updateDepartment({ departmentId, orgId, requestBody }) as unknown as Promise<TData>, ...options });
/**
* Update User In Organization
* @param data The data for the request.
* @param data.orgId
* @param data.userId
* @param data.requestBody
* @returns UserRead Successful Response
* @throws ApiError
*/
export const useOrgServiceUpdateUserInOrganization = <TData = Common.OrgServiceUpdateUserInOrganizationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  orgId: string;
  requestBody: ManagedUserCreate;
  userId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  orgId: string;
  requestBody: ManagedUserCreate;
  userId: string;
}, TContext>({ mutationFn: ({ orgId, requestBody, userId }) => OrgService.updateUserInOrganization({ orgId, requestBody, userId }) as unknown as Promise<TData>, ...options });
/**
* Invite User To Organization By Email
* @param data The data for the request.
* @param data.orgId
* @param data.userEmail
* @param data.requestBody
* @returns unknown Successful Response
* @throws ApiError
*/
export const useOrgServiceInviteUserToOrganizationByEmail = <TData = Common.OrgServiceInviteUserToOrganizationByEmailMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  orgId: string;
  requestBody: ManagedUserInvite;
  userEmail: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  orgId: string;
  requestBody: ManagedUserInvite;
  userEmail: string;
}, TContext>({ mutationFn: ({ orgId, requestBody, userEmail }) => OrgService.inviteUserToOrganizationByEmail({ orgId, requestBody, userEmail }) as unknown as Promise<TData>, ...options });
/**
* Users:Patch Current User
* @param data The data for the request.
* @param data.requestBody
* @returns UserRead Successful Response
* @throws ApiError
*/
export const useUserServiceUsersPatchCurrentUser = <TData = Common.UserServiceUsersPatchCurrentUserMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: UserUpdate;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: UserUpdate;
}, TContext>({ mutationFn: ({ requestBody }) => UserService.usersPatchCurrentUser({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Users:Patch User
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @returns UserRead Successful Response
* @throws ApiError
*/
export const useUserServiceUsersPatchUser = <TData = Common.UserServiceUsersPatchUserMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: UserUpdate;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: UserUpdate;
}, TContext>({ mutationFn: ({ id, requestBody }) => UserService.usersPatchUser({ id, requestBody }) as unknown as Promise<TData>, ...options });
/**
* Weaviate Delete Index
* Delete an index from Weaviate.
* @param data The data for the request.
* @param data.indexName
* @param data.self
* @returns unknown Successful Response
* @throws ApiError
*/
export const useWeaviateServiceWeaviateDeleteIndex = <TData = Common.WeaviateServiceWeaviateDeleteIndexMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  indexName: string;
  self: unknown;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  indexName: string;
  self: unknown;
}, TContext>({ mutationFn: ({ indexName, self }) => WeaviateService.weaviateDeleteIndex({ indexName, self }) as unknown as Promise<TData>, ...options });
/**
* Users:Delete User
* @param data The data for the request.
* @param data.id
* @returns void Successful Response
* @throws ApiError
*/
export const useUserServiceUsersDeleteUser = <TData = Common.UserServiceUsersDeleteUserMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => UserService.usersDeleteUser({ id }) as unknown as Promise<TData>, ...options });
/**
* Remove User From Organization
* @param data The data for the request.
* @param data.orgId
* @param data.userId
* @returns unknown Successful Response
* @throws ApiError
*/
export const useOrgServiceRemoveUserFromOrganization = <TData = Common.OrgServiceRemoveUserFromOrganizationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  orgId: string;
  userId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  orgId: string;
  userId: string;
}, TContext>({ mutationFn: ({ orgId, userId }) => OrgService.removeUserFromOrganization({ orgId, userId }) as unknown as Promise<TData>, ...options });
