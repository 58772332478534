import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";

import "./index.css";
import * as dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import { Bounce, ToastContainer } from "react-toastify";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import { NextUIProvider } from "@nextui-org/react";
import { OpenAPI, UserRead } from "./openapi/requests";
import { config } from "./utils";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import _ from "lodash";
import "react-toastify/dist/ReactToastify.min.css";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: config.sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  context: { user: null } as { user: UserRead | null },
});
OpenAPI.BASE = config.backendUrl;
OpenAPI.WITH_CREDENTIALS = true;
OpenAPI.interceptors.response.use((response: AxiosResponse) => {
  if (response.status === 401) {
    if (_.isEqual(response.data, { detail: "Unauthorized" })) {
      if (!window.location.pathname.startsWith("/auth")) {
        window.location.pathname = "/auth/sign-in";
      }
    }
  }
  return response;
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// import { createServer } from "miragejs";
// import { BASE_URL, UPLOAD_URL, uploadFile } from "./api.ts";

// if (process.env.NODE_ENV === "development") {
//   createServer({
//     routes() {
//       this.post(
//         UPLOAD_URL,
//         (): Awaited<ReturnType<typeof uploadFile>> => {
//           return { message: "Success" };
//         },
//         { timing: 2000 }
//       );

//       this.passthrough(BASE_URL + "/**");
//     },
//   });
// }

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const originalWarn = console.error;
console.error = (...args) => {
  if (
    args.length > 0 &&
    typeof args[0] === "string" &&
    args[0].includes("Warning: Failed %s type: %s%s")
  ) {
    return;
  }
  originalWarn(...args);
};

// Render the app
const rootElement = document.getElementById("root")!;
export const queryClient = new QueryClient();

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <NextUIProvider>
          <RouterProvider router={router} />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            newestOnTop={false}
            closeOnClick
            pauseOnHover
            theme="dark"
            transition={Bounce}
          />
        </NextUIProvider>
      </QueryClientProvider>
    </StrictMode>
  );
}
