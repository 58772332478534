import { zodResolver } from "@hookform/resolvers/zod";
import {
  ModalProps,
  Modal,
  ModalHeader,
  ModalBody,
  SelectItem,
  ModalFooter,
} from "@nextui-org/react";
import { useForm, Controller } from "react-hook-form";
import { CustomButton } from "../custom/CustomButton";
import { CustomCheckbox } from "../custom/CustomCheckbox";
import { CustomCheckboxGroup } from "../custom/CustomCheckboxGroup";
import { CustomInput } from "../custom/CustomInput";
import { CustomModalContent } from "../custom/CustomModal";
import { CustomSelect } from "../custom/CustomSelect";
import { queryClient } from "../main";
import {
  useDataServiceGetDatasources,
  useOrgServiceCreateUserForOrganization,
  useOrgServiceGetOrganizationUsersKey,
  useOrgServiceGetOrganizationDepartments,
  UseOrgServiceGetOrganizationDepartmentsKeyFn,
  useOrgServiceUpdateUserInOrganization,
} from "../openapi/queries";
import {
  CreateUserWithPermissionsSchema,
  createUserWithPermissionsSchema,
} from "../schemas/createUserWithPermissions";
import { getDatasourcePermissionView } from "../utils";
import { OrganizationRead, UserRead } from "../openapi/requests";
import { toast } from "react-toastify";
import { useCallback, useEffect } from "react";

function NewUserModal({
  onClose,
  user,
  organization,
  ...props
}: Omit<ModalProps, "children"> & {
  onClose: () => void;
  user?: UserRead;
  organization: OrganizationRead;
}) {
  const datasources = useDataServiceGetDatasources();

  const createUserForOrganization = useOrgServiceCreateUserForOrganization({
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [useOrgServiceGetOrganizationUsersKey],
      });

      toast.success("User created");

      onClose();
    },
    onError: () => {
      toast.error("Error creating user");
    },
  });

  const updateUserForOrganization = useOrgServiceUpdateUserInOrganization({
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [useOrgServiceGetOrganizationUsersKey],
      });

      toast.success("User updated");
      onClose();
    },
    onError: () => {
      toast.error("Error updating user");
    },
  });

  const departments = useOrgServiceGetOrganizationDepartments(
    {
      orgId: organization.id,
    },
    UseOrgServiceGetOrganizationDepartmentsKeyFn({ orgId: organization.id }),
    { enabled: organization !== undefined }
  );

  const { handleSubmit, register, control, reset } =
    useForm<CreateUserWithPermissionsSchema>({
      resolver: zodResolver(createUserWithPermissionsSchema),
    });

  const onSubmit = useCallback(
    ({
      role,
      datasources,
      departmentId,
      ...data
    }: CreateUserWithPermissionsSchema) => {
      if (user) {
        updateUserForOrganization.mutate({
          orgId: organization.id,
          userId: user.id,
          requestBody: {
            ...data,
            roles: [role],
            permissions: datasources.map((datasource) =>
              getDatasourcePermissionView(
                organization.id,
                departmentId,
                "*",
                datasource
              )
            ),
            department_id: departmentId,
          },
        });
      } else {
        createUserForOrganization.mutate({
          orgId: organization.id,
          requestBody: {
            ...data,
            roles: [role],
            permissions: datasources.map((datasource) =>
              getDatasourcePermissionView(
                organization.id,
                departmentId,
                "*",
                datasource
              )
            ),
            department_id: departmentId,
          },
        });
      }
    },
    [
      createUserForOrganization,
      organization.id,
      updateUserForOrganization,
      user,
    ]
  );

  useEffect(() => {
    if (user) {
      reset({
        role: user.roles[0],
        permissions: user.permissions,
        full_name: user.full_name,
        email: user.email,
        password: "",
        departmentId: user.department?.id ?? "",
        datasources: user.permissions.map((permission) => {
          const parts = permission.split(":");
          return parts[parts.length - 2];
        }),
      });
    } else {
      reset({
        role: "user",
        permissions: [],
        full_name: "",
        email: "",
        password: "",
        departmentId: "",
        datasources: [],
      });
    }
  }, [user, reset]);

  return (
    <Modal
      {...props}
      onClose={() => {
        onClose();
      }}
      size="4xl"
    >
      <CustomModalContent>
        <>
          <ModalHeader>Add new user</ModalHeader>
          <ModalBody>
            <form
              id="create-user-form"
              className="*:mb-6"
              onSubmit={handleSubmit(onSubmit, console.log)}
            >
              <div className="flex gap-10">
                <div className="flex-1">
                  <div className="flex gap-4 flex-col">
                    <p>Personal information</p>
                    <Controller
                      control={control}
                      name="full_name"
                      render={({ field, formState: { errors } }) => (
                        <CustomInput
                          label="Full name"
                          labelPlacement="outside"
                          placeholder="User's full name"
                          color="secondary-light"
                          errorMessage={errors.full_name?.message}
                          isInvalid={!!errors.full_name}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="email"
                      render={({ field, formState: { errors } }) => (
                        <CustomInput
                          label="Email"
                          labelPlacement="outside"
                          placeholder="User's email"
                          color="secondary-light"
                          errorMessage={errors.email?.message}
                          isInvalid={!!errors.email}
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="password"
                      render={({ field, formState: { errors } }) => (
                        <CustomInput
                          label="Password"
                          labelPlacement="outside"
                          placeholder="User's password"
                          color="secondary-light"
                          errorMessage={errors.password?.message}
                          isInvalid={!!errors.password}
                          {...field}
                        />
                      )}
                    />
                  </div>

                  <div className="flex gap-4 flex-col mt-6">
                    <p>Account</p>
                    <div className="flex flex-4 gap-4">
                      {departments.isSuccess && (
                        <CustomSelect
                          {...register("departmentId")}
                          color="secondary-light"
                          label="Department"
                          disallowEmptySelection
                          items={departments.data}
                        >
                          {departments.data.map((item) => (
                            <SelectItem key={item.id} value={item.id}>
                              {item.name}
                            </SelectItem>
                          ))}
                        </CustomSelect>
                      )}

                      <CustomSelect
                        {...register("role")}
                        color="secondary-light"
                        label="Role"
                        disallowEmptySelection
                        items={
                          [
                            { label: "User", value: "user" },
                            { label: "Admin", value: "admin" },
                            { label: "Owner", value: "owner" },
                          ] as const
                        }
                      >
                        <SelectItem key="user" value={"user"}>
                          User
                        </SelectItem>
                        <SelectItem key="admin" value={"admin"}>
                          Admin
                        </SelectItem>
                        <SelectItem key="owner" value={"owner"}>
                          Owner
                        </SelectItem>
                      </CustomSelect>
                    </div>
                  </div>
                </div>

                <div className="flex gap-4 flex-col max-w-[500px] min-w-[350px]">
                  {/* <p>Dashboards</p>
                      <Controller
                        control={control}
                        name="dashboards"
                        render={({ field: { onChange, value } }) => (
                          <CheckboxGroup
                            label="Choose dashboards"
                            value={value.filter((v) => v.includes(""))}
                            onValueChange={onChange}
                          >
                            {datasources.data?.map((p) => (
                              <CustomCheckbox
                                key={p.name}
                                value={p.id}
                                color="primary"
                              >
                                {p.name}
                              </CustomCheckbox>
                            ))}
                          </CheckboxGroup>
                        )}
                      /> */}

                  <p>Data visibility</p>
                  <Controller
                    control={control}
                    name="datasources"
                    render={({ field: { onChange, value } }) => (
                      <CustomCheckboxGroup
                        label="Resources"
                        value={value.filter((v) => v.includes(""))}
                        onValueChange={onChange}
                        size="sm"
                        color="secondary-light"
                      >
                        {datasources.data?.map((p) => (
                          <CustomCheckbox
                            key={p.name}
                            value={p.id}
                            color="primary"
                            size="sm"
                          >
                            {p.name}
                          </CustomCheckbox>
                        ))}
                      </CustomCheckboxGroup>
                    )}
                  />
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <CustomButton
              form="create-user-form"
              type="submit"
              color="primary"
              isLoading={createUserForOrganization.isPending}
            >
              Add user
            </CustomButton>
          </ModalFooter>
        </>
      </CustomModalContent>
    </Modal>
  );
}

export default NewUserModal;
