import { Input, InputProps } from "@nextui-org/react";
import { Ref, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

export type AuthInputProps = Omit<
  InputProps,
  "size" | "labelPlacement" | "classNames" | "label"
> & {
  label: string;
};

const AuthInput = forwardRef(function AuthInput(
  { className, label, placeholder, isInvalid, ...props }: AuthInputProps,
  ref: Ref<HTMLInputElement>
) {
  return (
    <Input
      ref={ref}
      className={twMerge("mb-4 rounded !mt-12", className)}
      {...props}
      size="sm"
      label={label}
      placeholder={placeholder ?? label}
      labelPlacement="outside"
      isInvalid={isInvalid ?? props.errorMessage !== undefined}
      classNames={{
        inputWrapper: [
          "bg-secondary-900",
          "h-[unset]",
          "!p-3",
          "group-data-[hover=true]:bg-secondary-900",
          "group-data-[focus=true]:bg-secondary-900",
          "group-data-[invalid=true]:border",
          "group-data-[invalid=true]:border-danger",
          "group-data-[invalid=true]:!bg-secondary-900",
        ],
        label: ["!text-secondary-600", "top-[12px]"],
        input: ["!text-[#F8FCFD]", "placeholder:text-secondary-300"],
        description: ["mt-2", "text-secondary-300"],
        errorMessage: ["mt-2"],
      }}
    />
  );
});

export default AuthInput;
