import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { v4 as uuidv4 } from "uuid";
import { MessageData } from "./types";
import { TrackerService } from "./openapi/requests";
import { filterEvents } from "./utils";

interface AppStore {
  loading: boolean;
  setLoading: (value: boolean) => void;
}

interface PromptStore {
  isOpen: boolean;
  text: string;
  onOpenChange: (value: boolean) => void;
  setText: (value: string) => void;
}

interface ChatStore {
  messages: MessageData[];
  resetMessages: () => void;
  addMessages: (...data: MessageData[]) => void;
  replaceMessage: (index: number, data: MessageData) => void;
  setMessages: (data: MessageData[]) => void;
  loadMessages: () => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  conversationId: string | null;
  setConversationId: (value: string | null) => void;
  createConversation: () => string;
}

export const useChatStore = create<
  ChatStore,
  [["zustand/persist", Pick<ChatStore, "conversationId">]]
>(
  persist(
    (set, get) => ({
      messages: [] as MessageData[],
      setMessages: (data: MessageData[]) => set(() => ({ messages: data })),
      resetMessages: () => set(() => ({ messages: [] })),
      addMessages: (...data: MessageData[]) =>
        set((state) => ({ messages: [...state.messages, ...data] })),
      replaceMessage: (index, data) => {
        set((state) => {
          index = index >= 0 ? index : state.messages.length + index;
          return {
            messages: [
              ...state.messages.slice(0, index),
              data,
              ...state.messages.slice(index + 1),
            ],
          };
        });
      },
      loadMessages: () => {
        const store = get();
        const conversationId = store.conversationId;

        if (conversationId === null) return;

        store.setLoading(true);

        TrackerService.getEvents({ conversationId })
          .then((history) => {
            if (history === null) {
              store.resetMessages();
            } else {
              // TODO: Fix typing here
              // @ts-expect-error Needs type fixing
              store.setMessages(filterEvents(history.events ?? []));
            }
          })
          .catch(() => {
            store.createConversation();
          });

        store.setLoading(false);
      },
      loading: false,
      setLoading: (value) => set(() => ({ loading: value })),
      conversationId: null,
      setConversationId: (value) => {
        set(() => ({ conversationId: value }));
        get().loadMessages();
      },
      createConversation: () => {
        const newId = uuidv4();
        set(() => ({
          conversationId: newId,
          messages: [],
        }));
        return newId;
      },
    }),
    {
      name: "chat-store",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({ conversationId: state.conversationId }),
    }
  )
);

export const useAppStore = create<AppStore>()((set) => ({
  loading: false,
  setLoading: (value) => set(() => ({ loading: value })),
}));

export const usePromptStore = create<PromptStore>()((set) => ({
  isOpen: false,
  text: "",
  onOpenChange: (value) => set(() => ({ isOpen: value })),
  setText: (value) => set(() => ({ text: value })),
}));
