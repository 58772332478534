import { createFileRoute } from "@tanstack/react-router";
import { AuthService } from "../openapi/requests";
import { CustomButton } from "../custom/CustomButton";
import { Link as RouterLink } from "@tanstack/react-router";

export const Route = createFileRoute("/auth/sign-out")({
  component: () => (
    <div>
      <RouterLink to="/auth/sign-in">
        <CustomButton color="primary">Sign in</CustomButton>
      </RouterLink>
    </div>
  ),
  loader: async () => {
    try {
      await AuthService.authJwtLogout();
    } catch {
      /* empty */
    }
  },
});
