import { Button, useDisclosure } from "@nextui-org/react";
import { Logout, Icon } from "iconsax-react";
import logo from "../assets/sidebar_logo.svg";
import arrow from "../assets/sidebar_arrow.svg";
import { Link } from "@tanstack/react-router";
import { twMerge } from "tailwind-merge";

import { Link as RouterLink } from "@tanstack/react-router";

function Sidebar({
  className,
  menuItems,
}: {
  className?: string;
  menuItems: { label: string; to: string; icon: Icon }[];
}) {
  const { onOpenChange, isOpen } = useDisclosure({ defaultOpen: true });
  const baseClassName = `flex-shrink-0 ${!isOpen ? "h-10 w-10" : "h-[56px] py-[10px] px-[12px] justify-start text-left"}`;

  return (
    <div
      className={twMerge(
        `bg-secondary-900 py-6 overflow-y-auto ${isOpen ? "px-[36px]" : "px-2"}`,
        className
      )}
    >
      <div
        className={
          "flex items-center mb-8 " +
          (isOpen ? "justify-between" : "justify-center")
        }
      >
        {isOpen && <img src={logo} height={32} width={32} />}
        <Button isIconOnly variant="light" size="sm" onClick={onOpenChange}>
          <img src={arrow} className={!isOpen ? "rotate-180" : undefined} />
        </Button>
      </div>
      <div
        className={
          "flex flex-col gap-6 " + (isOpen ? "items-stretch" : "items-center")
        }
      >
        {menuItems.map(({ label, icon: Icon, to }) => (
          <Button
            key={label}
            radius="sm"
            to={to}
            as={Link}
            activeProps={{
              className: "!font-medium !bg-secondary-800 !text-[#F8FCFD]",
            }}
            inactiveProps={{
              className: "!text-secondary-300",
            }}
            variant="light"
            className={baseClassName}
            startContent={
              isOpen && Icon && <Icon style={{ flexShrink: 0 }} size="24" />
            }
            isIconOnly={!isOpen}
          >
            {isOpen ? label : <Icon style={{ flexShrink: 0 }} size="20" />}
          </Button>
        ))}

        <RouterLink to="/auth/sign-out" preload={false}>
          <Button
            className="mt-auto text-white border-secondary-700 border justify-between px-4 py-6 w-full"
            endContent={
              isOpen && <Logout style={{ flexShrink: 0 }} size="24" />
            }
            variant="bordered"
            isIconOnly={!isOpen}
          >
            {isOpen ? (
              "Log out"
            ) : (
              <Logout style={{ flexShrink: 0 }} size="20" />
            )}
          </Button>
        </RouterLink>
      </div>
    </div>
  );
}

export default Sidebar;
