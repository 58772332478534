import { InputProps } from "@nextui-org/react";
import { Ref, useState } from "react";
import { Eye, EyeSlash } from "iconsax-react";
import { forwardRef } from "react";

function withHiddable<T extends Pick<InputProps, "endContent" | "type">>(
  Component: React.ComponentType<T>
) {
  return forwardRef(function HiddableTextInput(
    props: T,
    ref: Ref<HTMLInputElement>
  ) {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => setIsVisible(!isVisible);

    return (
      <Component
        ref={ref}
        {...props}
        type={isVisible ? "text" : "password"}
        endContent={
          <button
            className="focus:outline-none"
            type="button"
            onClick={toggleVisibility}
          >
            {isVisible ? (
              <Eye
                className="text-secondary-300 pointer-events-none"
                size="16"
              />
            ) : (
              <EyeSlash
                className="text-secondary-300 pointer-events-none"
                size="16"
              />
            )}
          </button>
        }
      />
    );
  });
}

export default withHiddable;
