import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { ChatEvent } from "./api";
import { MessageData } from "./types";

export const PERIODS = ["month", "quarter", "year"] as const;
export type Period = (typeof PERIODS)[number];

export const generateConversationId = () => {
  return uuidv4();
};

export const getDatasourcePermissionView = (
  orgId: string,
  departmentId: string,
  userId: string,
  datasourceId: string
) =>
  `organization:${orgId}:department:${departmentId}:user:${userId}:datasources:${datasourceId}:use`;

export const MONTHS = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
} as const;

const CONVERSATION_IDS_KEY = "conversation_ids";

export async function* iterateStream<R>(stream: ReadableStream<R>) {
  const reader = stream.getReader();
  try {
    while (true) {
      const { done, value } = await reader.read();
      if (done) return;
      yield value;
    }
  } finally {
    reader.releaseLock();
  }
}

export function convertMarkdownTable(value: string) {
  return value.split("\n").map((line) =>
    line
      .slice(1, line.length - 1)
      .split("|")
      .map((v) => v.trim())
  );
}

export const getConversationIds = () => {
  const idsString = window.localStorage.getItem(CONVERSATION_IDS_KEY);

  if (!idsString) return [];
  return idsString.split(",");
};

export const setConversationIds = (ids: string[]) => {
  window.localStorage.setItem(CONVERSATION_IDS_KEY, ids.join(","));
};

export const getTimestampLabel = (v: string | Date): string => {
  // TODO: Timezones
  const timestamp = dayjs(v);
  if (timestamp.isToday()) {
    return "Today";
  }

  if (timestamp.isYesterday()) {
    return "Yesterday";
  }

  const now = dayjs();
  const oneWeekAgo = now.subtract(1, "week");
  const twoWeeksAgo = now.subtract(2, "week");
  const oneMonthAgo = now.subtract(1, "month");

  if (timestamp.isAfter(oneWeekAgo)) {
    return "Previous 7 days";
  }

  if (timestamp.isAfter(twoWeeksAgo)) {
    return "Previous week";
  }

  if (timestamp.isAfter(oneMonthAgo)) {
    return "This month";
  }

  return "Long time ago...";
};

export function getRandom<T>(list: T[]): T {
  return list[Math.floor(Math.random() * list.length)];
}

export function convertToUnits(value: number): string {
  if (Math.abs(value) > 10e9) return `${(value / 10e8).toFixed(2)}B`;
  if (Math.abs(value) > 10e6) return `${(value / 10e5).toFixed(2)}M`;
  if (Math.abs(value) > 10e3) return `${(value / 10e2).toFixed(2)}K`;
  return value.toFixed(2);
}

export const config = {
  backendUrl:
    (import.meta.env.VITE_BACKEND_URL as string) ??
    "https://api.geniusvoice.ai",
  streamMessages: ["true", "1"].includes(
    import.meta.env.VITE_STREAM_MESSAGES as string
  ),
  sentryDsn: import.meta.env.VITE_SENTRY_DSN as string,
};

export const filterEvents = (
  events: ChatEvent[],
  typewrite = false
): MessageData[] =>
  events.flatMap((event) => {
    if (event.action === "user_message") {
      const msgData: MessageData = {
        id: event.id,
        own: true,
        text: event.data.text,
        streaming: false,
        streamed: false,
        typewrite: false,
      };
      return [msgData];
    } else if (event.action === "bot_message") {
      const msgData: MessageData = {
        id: event.id,
        own: false,
        text: event.data.text,
        streaming: event.meta.streaming ?? false,
        streamed: event.meta.streamed ?? false,
        typewrite,
      };

      if (event.data?.markdown) {
        msgData.table = convertMarkdownTable(event.data.markdown);
      }

      if (event.data.json?.bar) {
        msgData.bar = event.data.json.bar;
      }

      if (event.data.json?.line) {
        msgData.line = event.data.json.line;
      }

      if (event.data.json?.title) {
        msgData.title = event.data.json.title;
      }

      if (event.data.json?.reasoning) {
        msgData.reasoning = event.data.json.reasoning;
      }

      if (event.data.json?.imageSrc) {
        msgData.imageSrc = event.data.json.imageSrc;
      }
      return [msgData];
    } else {
      return [];
    }
  });
