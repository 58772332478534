import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Link as RouterLink } from "@tanstack/react-router";
import { z } from "zod";
import { CustomButton } from "../custom/CustomButton";
import { Link } from "@nextui-org/react";
import { ArrowLeft } from "iconsax-react";
import { PinField } from "react-pin-field";
import { useEffect, useRef, useState } from "react";
import {
  useAuthServiceVerifyRequestToken,
  useAuthServiceVerifyVerify,
} from "../openapi/queries";
import { ApiError } from "../openapi/requests";
import _ from "lodash";
import ErrorMessage from "../components/auth/ErrorMessage";

const verifySchema = z.object({ code: z.coerce.string().optional() });

function Verify() {
  const searchParams = Route.useSearch();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement[]>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const verify = useAuthServiceVerifyVerify({
    onSuccess: () => {
      void navigate({ to: "/bi" });
    },
    onError: (err) => {
      if (err instanceof ApiError) {
        if (_.isEqual(err.body, { detail: "" })) {
          void navigate({ to: "/bi" });
          return;
        } else if (_.isEqual(err.body, { detail: "VERIFY_USER_BAD_TOKEN" })) {
          setErrorMessage("Wrong code");
          (inputRef.current ?? []).forEach((i) =>
            i.setCustomValidity("Wrong code")
          );
          return;
        } else {
          setErrorMessage("Unknwon server error");
          (inputRef.current ?? []).forEach((i) =>
            i.setCustomValidity("Unknown server error")
          );
        }
      }
    },
  });
  const requestVerify = useAuthServiceVerifyRequestToken({
    onSuccess: () => {
      setRequested(true);
    },
  });
  const [code, setCode] = useState<string | null>(null);
  const [requested, setRequested] = useState(false);

  const content =
    searchParams.code ?? requested ? (
      <>
        <p className="text-tiny text-secondary-300 font-light mb-10">
          Please type the code that was provided for you in the email and press{" "}
          {'"Verify"'}.
        </p>
        <div className="flex justify-between">
          <PinField
            ref={inputRef}
            length={6}
            className={"pin-field"}
            inputMode="numeric"
            validate="0123456789"
            onComplete={(code) => {
              setCode(code);
              verify.mutate({ requestBody: { token: code } });
            }}
          />
        </div>
        <div className="my-2 flex flex-col flex-end items-stretch mt-10 gap-4">
          {errorMessage && <ErrorMessage className="" text={errorMessage} />}
          <CustomButton
            ref={buttonRef}
            isLoading={verify.isPending}
            onClick={() => {
              if (code) {
                verify.mutate({ requestBody: { token: code } });
              }
            }}
          >
            Submit
          </CustomButton>
        </div>
      </>
    ) : (
      <>
        <p className="text-tiny text-secondary-300 font-light mb-10">
          Your email has not been verified yet. Please press the button below to
          send the email with a verification code.
        </p>
        <CustomButton
          isLoading={requestVerify.isPending}
          onClick={() => {
            requestVerify.mutate();
          }}
        >
          Send verification
        </CustomButton>
      </>
    );

  useEffect(() => {
    if (searchParams.code) {
      searchParams.code
        .toString()
        .split("")
        .forEach((v, idx) => {
          const field = (inputRef.current ?? [])[idx];
          if (field) {
            field.value = v;
          }
        });
    } else {
      if (inputRef.current) {
        inputRef.current.forEach((f) => (f.value = ""));
      }
    }
  }, [searchParams.code]);

  return (
    <div className="w-[300px]">
      <Link
        as={RouterLink}
        to="/auth/sign-out"
        className="text-secondary-200 text-sm mb-8"
        preload={false}
      >
        <ArrowLeft size="14" className="mr-1" />
        Back
      </Link>
      <p className="text-2xl text-[#F8FCFD] font-semibold mb-4">
        Verify your account
      </p>

      {content}
    </div>
  );
}
export const Route = createFileRoute("/auth/verify")({
  validateSearch: (search) => verifySchema.parse(search),
  component: () => <Verify />,
});
