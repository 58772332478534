// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/// @ts-nocheck
import { extendVariants, Button, button } from "@nextui-org/react";

export const CustomButton = extendVariants(Button, {
  variants: {
    ...button.variants,
    variant: {
      ...button.variants.variant,
      "bordered-gray": "border border-secondary-800",
    },
    size: {
      ...button.variants.size,
      sm: "px-3 min-w-16 h-8 text-tiny gap-2 rounded-small",
      md: "px-4 min-w-22 h-10 text-small gap-2 rounded-medium",
      lg: "px-6 min-w-24 h-12 text-medium gap-3 rounded-large",
    },
    color: {
      ...button.variants.color,
      primary: "text-[#1A1A2E]",
    },
  },
  defaultVariants: {
    color: "primary",
    ...button.defaultVariants,
  },
  compoundVariants: [
    ...button.compoundVariants,
    {
      variant: "light",
      color: "primary",
      class: "text-[#F8FCFD]",
    },
    {
      class: "box-content min-w-[fit-content]",
    },
    {
      variant: "bordered-gray",
      color: "primary",
      class: "bg-transparent text-primary",
    },
    {
      variant: "bordered-gray",
      color: "default",
      class: "bg-transparent text-secondary-300",
    },
  ],
});
