import { createFileRoute, useNavigate } from "@tanstack/react-router";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import { CustomButton } from "../custom/CustomButton";
import { CustomModalContent } from "../custom/CustomModal";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CreateOrganizationSchema,
  createOrganizationSchema,
} from "../schemas/createOrganization";
import {
  UseUserServiceUsersCurrentUserKeyFn,
  useOrgServiceCreateOrganization,
} from "../openapi/queries";
import { CustomInput } from "../custom/CustomInput";
import { queryClient } from "../main";

function NoOrganization() {
  const { register, handleSubmit } = useForm<CreateOrganizationSchema>({
    resolver: zodResolver(createOrganizationSchema),
  });
  const navigate = useNavigate();
  const { mutate } = useOrgServiceCreateOrganization({
    onSuccess: async () => {
      onClose();
      await queryClient.invalidateQueries({
        queryKey: UseUserServiceUsersCurrentUserKeyFn(),
      });
      await navigate({ to: "/users" });
    },
  });
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  return (
    <>
      <Modal onOpenChange={onOpenChange} isOpen={isOpen}>
        <CustomModalContent>
          {() => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Create organization
              </ModalHeader>
              <ModalBody>
                <form
                  id="create-organization-form"
                  onSubmit={handleSubmit((data) => {
                    mutate({ requestBody: data });
                  })}
                >
                  <CustomInput
                    color="secondary-light"
                    label="Organization name"
                    labelPlacement="outside"
                    {...register("name")}
                  />
                </form>
              </ModalBody>
              <ModalFooter>
                <CustomButton
                  color="primary"
                  form="create-organization-form"
                  type="submit"
                >
                  Create
                </CustomButton>
              </ModalFooter>
            </>
          )}
        </CustomModalContent>
      </Modal>

      <div className="flex justify-center items-center h-full ">
        <Card className="max-w-[400px] bg-secondary-800 text-secondary-300">
          <CardHeader className="flex gap-3">
            <div className="flex flex-col">
              <p className="text-md">You are not part of any organization</p>
            </div>
          </CardHeader>
          <Divider />
          <CardBody>
            <p className="text-sm">
              Please create a new organization or ask your organization{"'"}s
              admin to invite you.
            </p>
          </CardBody>
          <Divider />
          <CardFooter>
            <CustomButton color="primary" onClick={() => onOpen()}>
              Create organization
            </CustomButton>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}

export const Route = createFileRoute("/_layout/no-organization")({
  component: () => <NoOrganization />,
});
