// generated with @7nohe/openapi-react-query-codegen@1.3.0 

import { UseQueryResult } from "@tanstack/react-query";
import { AuthService, BiService, DataService, DatabaseService, DefaultService, LangChainService, MessagesService, OrgService, TrackerService, UserService, WeaviateService } from "../requests/services.gen";
export type DatabaseServiceDbHealthDefaultResponse = Awaited<ReturnType<typeof DatabaseService.dbHealth>>;
export type DatabaseServiceDbHealthQueryResult<TData = DatabaseServiceDbHealthDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDatabaseServiceDbHealthKey = "DatabaseServiceDbHealth";
export const UseDatabaseServiceDbHealthKeyFn = () => [useDatabaseServiceDbHealthKey];
export type DatabaseServiceRunSqlQueryDefaultResponse = Awaited<ReturnType<typeof DatabaseService.runSqlQuery>>;
export type DatabaseServiceRunSqlQueryQueryResult<TData = DatabaseServiceRunSqlQueryDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDatabaseServiceRunSqlQueryKey = "DatabaseServiceRunSqlQuery";
export const UseDatabaseServiceRunSqlQueryKeyFn = ({ query, tableName }: {
  query: string;
  tableName: string;
}, queryKey?: Array<unknown>) => [useDatabaseServiceRunSqlQueryKey, ...(queryKey ?? [{ query, tableName }])];
export type LangChainServiceGetTableInfoDefaultResponse = Awaited<ReturnType<typeof LangChainService.getTableInfo>>;
export type LangChainServiceGetTableInfoQueryResult<TData = LangChainServiceGetTableInfoDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useLangChainServiceGetTableInfoKey = "LangChainServiceGetTableInfo";
export const UseLangChainServiceGetTableInfoKeyFn = () => [useLangChainServiceGetTableInfoKey];
export type DefaultServiceHealthDefaultResponse = Awaited<ReturnType<typeof DefaultService.health>>;
export type DefaultServiceHealthQueryResult<TData = DefaultServiceHealthDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDefaultServiceHealthKey = "DefaultServiceHealth";
export const UseDefaultServiceHealthKeyFn = () => [useDefaultServiceHealthKey];
export type WeaviateServiceWeaviateHealthDefaultResponse = Awaited<ReturnType<typeof WeaviateService.weaviateHealth>>;
export type WeaviateServiceWeaviateHealthQueryResult<TData = WeaviateServiceWeaviateHealthDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useWeaviateServiceWeaviateHealthKey = "WeaviateServiceWeaviateHealth";
export const UseWeaviateServiceWeaviateHealthKeyFn = ({ self }: {
  self: unknown;
}, queryKey?: Array<unknown>) => [useWeaviateServiceWeaviateHealthKey, ...(queryKey ?? [{ self }])];
export type WeaviateServiceWeaviateInfoDefaultResponse = Awaited<ReturnType<typeof WeaviateService.weaviateInfo>>;
export type WeaviateServiceWeaviateInfoQueryResult<TData = WeaviateServiceWeaviateInfoDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useWeaviateServiceWeaviateInfoKey = "WeaviateServiceWeaviateInfo";
export const UseWeaviateServiceWeaviateInfoKeyFn = ({ self }: {
  self: unknown;
}, queryKey?: Array<unknown>) => [useWeaviateServiceWeaviateInfoKey, ...(queryKey ?? [{ self }])];
export type WeaviateServiceWeaviateIndexesDefaultResponse = Awaited<ReturnType<typeof WeaviateService.weaviateIndexes>>;
export type WeaviateServiceWeaviateIndexesQueryResult<TData = WeaviateServiceWeaviateIndexesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useWeaviateServiceWeaviateIndexesKey = "WeaviateServiceWeaviateIndexes";
export const UseWeaviateServiceWeaviateIndexesKeyFn = ({ self }: {
  self: unknown;
}, queryKey?: Array<unknown>) => [useWeaviateServiceWeaviateIndexesKey, ...(queryKey ?? [{ self }])];
export type WeaviateServiceWeaviateIndexExistDefaultResponse = Awaited<ReturnType<typeof WeaviateService.weaviateIndexExist>>;
export type WeaviateServiceWeaviateIndexExistQueryResult<TData = WeaviateServiceWeaviateIndexExistDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useWeaviateServiceWeaviateIndexExistKey = "WeaviateServiceWeaviateIndexExist";
export const UseWeaviateServiceWeaviateIndexExistKeyFn = ({ indexName, self }: {
  indexName: string;
  self: unknown;
}, queryKey?: Array<unknown>) => [useWeaviateServiceWeaviateIndexExistKey, ...(queryKey ?? [{ indexName, self }])];
export type WeaviateServiceWeaviateGetIndexCountDefaultResponse = Awaited<ReturnType<typeof WeaviateService.weaviateGetIndexCount>>;
export type WeaviateServiceWeaviateGetIndexCountQueryResult<TData = WeaviateServiceWeaviateGetIndexCountDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useWeaviateServiceWeaviateGetIndexCountKey = "WeaviateServiceWeaviateGetIndexCount";
export const UseWeaviateServiceWeaviateGetIndexCountKeyFn = ({ indexName, self }: {
  indexName: string;
  self: unknown;
}, queryKey?: Array<unknown>) => [useWeaviateServiceWeaviateGetIndexCountKey, ...(queryKey ?? [{ indexName, self }])];
export type WeaviateServiceWeaviateSearchIndexDefaultResponse = Awaited<ReturnType<typeof WeaviateService.weaviateSearchIndex>>;
export type WeaviateServiceWeaviateSearchIndexQueryResult<TData = WeaviateServiceWeaviateSearchIndexDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useWeaviateServiceWeaviateSearchIndexKey = "WeaviateServiceWeaviateSearchIndex";
export const UseWeaviateServiceWeaviateSearchIndexKeyFn = ({ indexName, query, self, topK }: {
  indexName: string;
  query?: string;
  self: unknown;
  topK?: number;
}, queryKey?: Array<unknown>) => [useWeaviateServiceWeaviateSearchIndexKey, ...(queryKey ?? [{ indexName, query, self, topK }])];
export type BiServiceGetSummaryDefaultResponse = Awaited<ReturnType<typeof BiService.getSummary>>;
export type BiServiceGetSummaryQueryResult<TData = BiServiceGetSummaryDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBiServiceGetSummaryKey = "BiServiceGetSummary";
export const UseBiServiceGetSummaryKeyFn = ({ tableName }: {
  tableName: string;
}, queryKey?: Array<unknown>) => [useBiServiceGetSummaryKey, ...(queryKey ?? [{ tableName }])];
export type BiServiceGetFilterOptionsDefaultResponse = Awaited<ReturnType<typeof BiService.getFilterOptions>>;
export type BiServiceGetFilterOptionsQueryResult<TData = BiServiceGetFilterOptionsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBiServiceGetFilterOptionsKey = "BiServiceGetFilterOptions";
export const UseBiServiceGetFilterOptionsKeyFn = ({ tableName }: {
  tableName: string;
}, queryKey?: Array<unknown>) => [useBiServiceGetFilterOptionsKey, ...(queryKey ?? [{ tableName }])];
export type TrackerServiceGetPromptLibraryDefaultResponse = Awaited<ReturnType<typeof TrackerService.getPromptLibrary>>;
export type TrackerServiceGetPromptLibraryQueryResult<TData = TrackerServiceGetPromptLibraryDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useTrackerServiceGetPromptLibraryKey = "TrackerServiceGetPromptLibrary";
export const UseTrackerServiceGetPromptLibraryKeyFn = () => [useTrackerServiceGetPromptLibraryKey];
export type TrackerServiceGetConversationsDefaultResponse = Awaited<ReturnType<typeof TrackerService.getConversations>>;
export type TrackerServiceGetConversationsQueryResult<TData = TrackerServiceGetConversationsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useTrackerServiceGetConversationsKey = "TrackerServiceGetConversations";
export const UseTrackerServiceGetConversationsKeyFn = () => [useTrackerServiceGetConversationsKey];
export type TrackerServiceGetEventsDefaultResponse = Awaited<ReturnType<typeof TrackerService.getEvents>>;
export type TrackerServiceGetEventsQueryResult<TData = TrackerServiceGetEventsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useTrackerServiceGetEventsKey = "TrackerServiceGetEvents";
export const UseTrackerServiceGetEventsKeyFn = ({ conversationId }: {
  conversationId: string;
}, queryKey?: Array<unknown>) => [useTrackerServiceGetEventsKey, ...(queryKey ?? [{ conversationId }])];
export type DataServiceGetDatasourcesDefaultResponse = Awaited<ReturnType<typeof DataService.getDatasources>>;
export type DataServiceGetDatasourcesQueryResult<TData = DataServiceGetDatasourcesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDataServiceGetDatasourcesKey = "DataServiceGetDatasources";
export const UseDataServiceGetDatasourcesKeyFn = () => [useDataServiceGetDatasourcesKey];
export type DataServiceGetDatasourceByIdDefaultResponse = Awaited<ReturnType<typeof DataService.getDatasourceById>>;
export type DataServiceGetDatasourceByIdQueryResult<TData = DataServiceGetDatasourceByIdDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDataServiceGetDatasourceByIdKey = "DataServiceGetDatasourceById";
export const UseDataServiceGetDatasourceByIdKeyFn = ({ datasourceId }: {
  datasourceId: string;
}, queryKey?: Array<unknown>) => [useDataServiceGetDatasourceByIdKey, ...(queryKey ?? [{ datasourceId }])];
export type UserServiceUsersCurrentUserDefaultResponse = Awaited<ReturnType<typeof UserService.usersCurrentUser>>;
export type UserServiceUsersCurrentUserQueryResult<TData = UserServiceUsersCurrentUserDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUserServiceUsersCurrentUserKey = "UserServiceUsersCurrentUser";
export const UseUserServiceUsersCurrentUserKeyFn = () => [useUserServiceUsersCurrentUserKey];
export type UserServiceUsersUserDefaultResponse = Awaited<ReturnType<typeof UserService.usersUser>>;
export type UserServiceUsersUserQueryResult<TData = UserServiceUsersUserDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUserServiceUsersUserKey = "UserServiceUsersUser";
export const UseUserServiceUsersUserKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useUserServiceUsersUserKey, ...(queryKey ?? [{ id }])];
export type OrgServiceGetOrganizationsDefaultResponse = Awaited<ReturnType<typeof OrgService.getOrganizations>>;
export type OrgServiceGetOrganizationsQueryResult<TData = OrgServiceGetOrganizationsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOrgServiceGetOrganizationsKey = "OrgServiceGetOrganizations";
export const UseOrgServiceGetOrganizationsKeyFn = () => [useOrgServiceGetOrganizationsKey];
export type OrgServiceGetOrganizationDefaultResponse = Awaited<ReturnType<typeof OrgService.getOrganization>>;
export type OrgServiceGetOrganizationQueryResult<TData = OrgServiceGetOrganizationDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOrgServiceGetOrganizationKey = "OrgServiceGetOrganization";
export const UseOrgServiceGetOrganizationKeyFn = ({ orgId }: {
  orgId: string;
}, queryKey?: Array<unknown>) => [useOrgServiceGetOrganizationKey, ...(queryKey ?? [{ orgId }])];
export type OrgServiceGetOrganizationUsersDefaultResponse = Awaited<ReturnType<typeof OrgService.getOrganizationUsers>>;
export type OrgServiceGetOrganizationUsersQueryResult<TData = OrgServiceGetOrganizationUsersDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOrgServiceGetOrganizationUsersKey = "OrgServiceGetOrganizationUsers";
export const UseOrgServiceGetOrganizationUsersKeyFn = ({ orgId }: {
  orgId: string;
}, queryKey?: Array<unknown>) => [useOrgServiceGetOrganizationUsersKey, ...(queryKey ?? [{ orgId }])];
export type OrgServiceGetOrganizationDepartmentsDefaultResponse = Awaited<ReturnType<typeof OrgService.getOrganizationDepartments>>;
export type OrgServiceGetOrganizationDepartmentsQueryResult<TData = OrgServiceGetOrganizationDepartmentsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOrgServiceGetOrganizationDepartmentsKey = "OrgServiceGetOrganizationDepartments";
export const UseOrgServiceGetOrganizationDepartmentsKeyFn = ({ orgId }: {
  orgId: string;
}, queryKey?: Array<unknown>) => [useOrgServiceGetOrganizationDepartmentsKey, ...(queryKey ?? [{ orgId }])];
export type MessagesServiceNewMessageMutationResult = Awaited<ReturnType<typeof MessagesService.newMessage>>;
export type MessagesServiceNewMessageStreamingMutationResult = Awaited<ReturnType<typeof MessagesService.newMessageStreaming>>;
export type WeaviateServiceWeaviateCreateIndexMutationResult = Awaited<ReturnType<typeof WeaviateService.weaviateCreateIndex>>;
export type WeaviateServiceWeaviateUpsertIndexMutationResult = Awaited<ReturnType<typeof WeaviateService.weaviateUpsertIndex>>;
export type BiServiceGetKpiMutationResult = Awaited<ReturnType<typeof BiService.getKpi>>;
export type BiServiceGetGrossProfitByPeriodMutationResult = Awaited<ReturnType<typeof BiService.getGrossProfitByPeriod>>;
export type BiServiceGetGrossProfitPerRegionMutationResult = Awaited<ReturnType<typeof BiService.getGrossProfitPerRegion>>;
export type BiServiceGetGrossProfitTrendMutationResult = Awaited<ReturnType<typeof BiService.getGrossProfitTrend>>;
export type BiServiceGetSeasonalSalesProfitMutationResult = Awaited<ReturnType<typeof BiService.getSeasonalSalesProfit>>;
export type BiServiceGetRevenuePerPeriodMutationResult = Awaited<ReturnType<typeof BiService.getRevenuePerPeriod>>;
export type BiServiceGetReclameCostPerPeriodMutationResult = Awaited<ReturnType<typeof BiService.getReclameCostPerPeriod>>;
export type BiServiceGetSalesForecastMutationResult = Awaited<ReturnType<typeof BiService.getSalesForecast>>;
export type BiServiceGetShellKpisMutationResult = Awaited<ReturnType<typeof BiService.getShellKpis>>;
export type BiServiceRunRegressionMutationResult = Awaited<ReturnType<typeof BiService.runRegression>>;
export type TrackerServiceCreatePromptMutationResult = Awaited<ReturnType<typeof TrackerService.createPrompt>>;
export type DataServiceGetPreviewMutationResult = Awaited<ReturnType<typeof DataService.getPreview>>;
export type DataServiceUploadFileMutationResult = Awaited<ReturnType<typeof DataService.uploadFile>>;
export type AuthServiceAuthJwtLoginMutationResult = Awaited<ReturnType<typeof AuthService.authJwtLogin>>;
export type AuthServiceAuthJwtLogoutMutationResult = Awaited<ReturnType<typeof AuthService.authJwtLogout>>;
export type AuthServiceRegisterRegisterMutationResult = Awaited<ReturnType<typeof AuthService.registerRegister>>;
export type AuthServiceResetForgotPasswordMutationResult = Awaited<ReturnType<typeof AuthService.resetForgotPassword>>;
export type AuthServiceResetResetPasswordMutationResult = Awaited<ReturnType<typeof AuthService.resetResetPassword>>;
export type AuthServiceVerifyRequestTokenMutationResult = Awaited<ReturnType<typeof AuthService.verifyRequestToken>>;
export type AuthServiceVerifyVerifyMutationResult = Awaited<ReturnType<typeof AuthService.verifyVerify>>;
export type OrgServiceCreateOrganizationMutationResult = Awaited<ReturnType<typeof OrgService.createOrganization>>;
export type OrgServiceCreateUserForOrganizationMutationResult = Awaited<ReturnType<typeof OrgService.createUserForOrganization>>;
export type OrgServiceCreateDepartmentMutationResult = Awaited<ReturnType<typeof OrgService.createDepartment>>;
export type OrgServiceAcceptInviteUserToOrganizationMutationResult = Awaited<ReturnType<typeof OrgService.acceptInviteUserToOrganization>>;
export type OrgServiceDeclineInviteUserToOrganizationMutationResult = Awaited<ReturnType<typeof OrgService.declineInviteUserToOrganization>>;
export type OrgServiceUpdateDepartmentMutationResult = Awaited<ReturnType<typeof OrgService.updateDepartment>>;
export type OrgServiceUpdateUserInOrganizationMutationResult = Awaited<ReturnType<typeof OrgService.updateUserInOrganization>>;
export type OrgServiceInviteUserToOrganizationByEmailMutationResult = Awaited<ReturnType<typeof OrgService.inviteUserToOrganizationByEmail>>;
export type UserServiceUsersPatchCurrentUserMutationResult = Awaited<ReturnType<typeof UserService.usersPatchCurrentUser>>;
export type UserServiceUsersPatchUserMutationResult = Awaited<ReturnType<typeof UserService.usersPatchUser>>;
export type WeaviateServiceWeaviateDeleteIndexMutationResult = Awaited<ReturnType<typeof WeaviateService.weaviateDeleteIndex>>;
export type UserServiceUsersDeleteUserMutationResult = Awaited<ReturnType<typeof UserService.usersDeleteUser>>;
export type OrgServiceRemoveUserFromOrganizationMutationResult = Awaited<ReturnType<typeof OrgService.removeUserFromOrganization>>;
