import { createFileRoute, useNavigate } from "@tanstack/react-router";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
} from "@nextui-org/react";
import { CustomButton } from "../custom/CustomButton";
import {
  UseUserServiceUsersCurrentUserKeyFn,
  useOrgServiceAcceptInviteUserToOrganization,
  useOrgServiceDeclineInviteUserToOrganization,
} from "../openapi/queries";
import { OrgService } from "../openapi/requests";
import { queryClient } from "../main";

function NoOrganization() {
  const { organization } = Route.useLoaderData();
  const navigate = useNavigate();
  const { mutate: accept } = useOrgServiceAcceptInviteUserToOrganization({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: UseUserServiceUsersCurrentUserKeyFn(),
      });
      await navigate({ to: "/bi" });
    },
  });
  const { mutate: decline } = useOrgServiceDeclineInviteUserToOrganization({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: UseUserServiceUsersCurrentUserKeyFn(),
      });
      await navigate({ to: "/bi" });
    },
  });
  return (
    <>
      <div className="flex justify-center items-center h-full ">
        <Card className="max-w-[400px] bg-secondary-800 text-secondary-300">
          <CardHeader className="flex gap-3">
            <div className="flex flex-col">
              <p className="text-md">
                {organization ? "You have been invited" : "Error"}
              </p>
            </div>
          </CardHeader>
          {organization && (
            <>
              <Divider />
              <CardBody>
                <p className="text-sm">
                  You have been invited to Organization {organization.name}.
                </p>
              </CardBody>
              <Divider />
              <CardFooter>
                <CustomButton
                  color="secondary"
                  className="mr-4"
                  onClick={() => decline({ orgId: organization.id })}
                >
                  Decline
                </CustomButton>
                <CustomButton
                  color="primary"
                  onClick={() => accept({ orgId: organization.id })}
                >
                  Accept
                </CustomButton>
              </CardFooter>
            </>
          )}
        </Card>
      </div>
    </>
  );
}

export const Route = createFileRoute("/_layout/invited")({
  component: () => <NoOrganization />,
  loader: async ({ context }) => {
    if (context.user?.organization_member?.status === "invited") {
      const orgId = context.user.organization_member.organization_id;
      return {
        organization: await OrgService.getOrganization({ orgId }),
      };
    }

    return { organization: null };
  },
});
